<template>
  <div>
    <section-banner
      v-for="(section, index) in sections"
      :key="index"
      :model="section"
    ></section-banner>
    <v-container class="section__content">
      <v-row>
        <v-col class="justify-center">
          <template>
            <v-card class="mx-auto" max-width="50%">
              <v-card-title>
                Access Our Policies and Disclaimers
              </v-card-title>

              <v-card-subtitle>
                Select the documentation your wish to download from the dropdown
                below.
              </v-card-subtitle>

              <v-card-actions>
                <v-btn class="download__link" text @click="show = !show">
                  Documents
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn icon @click="show = !show">
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>

              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>

                  <v-card-text>
                    <p>
                      <a
                        class="download__link"
                        href="/documents/NLA-Legal--NLAA-joint-terms-of-engagement.doc"
                        target="_blank"
                      >
                        Terms of Engagement
                      </a>
                    </p>
                    <p>
                      <a
                        class="download__link"
                        href="/documents/NLA-DP-Policy.pdf"
                        target="_blank"
                      >
                        Data Privacy Policy
                      </a>
                    </p>
                    <p>
                      <a
                        class="download__link"
                        href="/documents/NLA-Legal-PAIA-Manual.pdf"
                        target="_blank"
                      >
                        PAIA Manual
                      </a>
                    </p>
                     <p>
                      <a
                        class="download__link"
                        href="/documents/NLA-Legal-POPI-policy.pdf"
                        target="_blank"
                      >
                        POPI Manual
                      </a>
                    </p>
                     <p>
                      <a
                        class="download__link"
                        href="/documents/NLA-Legal-E-Commerce-Terms-Conditions.pdf"
                        target="_blank"
                      >
                        E-Commerce Terms & Conditions
                      </a>
                    </p>
                    <p>
                      <a
                        class="download__link"
                        href="/documents/NLA-Website-Disclaimer.pdf"
                        target="_blank"
                      >
                        Website Disclaimer
                      </a>
                    </p>
                    <p>
                      <a
                        class="download__link"
                        href="/documents/NLA-ABC-Policy.pdf"
                        target="_blank"
                      >
                        ABC Policy
                      </a>
                    </p>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectionBanner from "@/components/Frontend/SectionBanner";

export default {
  name: "PoliciesDisclaimers",
  components: {
    SectionBanner,
  },
  data() {
    return {
      show: false,
      sections: [
        {
          Title: "Policies & Disclaimers",
          Subtitle: "The fine print...",
          Disclaimer: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
